$bg-color: #d8f3dc;
$text-color: #042c14;

.html,
body {
  background-color: $bg-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    margin: 2.5rem 0;
    overflow: auto;
  }

}

.main {
  width: 100vw;
  height: 100vh;
  background-color: $bg-color;
  color: $text-color;
  position: relative;
  padding: 0;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > .information {
    flex: 1;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .information__grid {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: min-content;
      gap: 0px 1rem;

      & > * {
        margin: 0 !important;
        padding: 0 5rem;
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 5rem 0;
        & > * {
          padding: 0 1rem;
        }
      }

      &__title {
        grid-column: 1 / 7;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: $text-color;
        text-align: left;

        & > * {
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          grid-column: 1;
          font-size: 1.25rem;
        }
      }

      &__subtitle {
        grid-column: 1 / 4;
        text-align: center;
        font-weight: 700;
        margin-bottom: 1rem;
        color: $text-color;
        text-align: left;

        &--right {
          grid-column: 4 / 7;

          p {
            font-size: 1.1587rem;
          }

          @media screen and (max-width: 768px) {
            grid-column: 1;
            p {
                font-size: 1rem;
            }
          }
        }

        & > h2 {
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          grid-column: 1;
        }
      }
    }
  }

  & > .bottom-image {
    & > img {
      margin-bottom: -1rem;
      object-fit: cover;
    }
  }
}

.icons {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    margin-right: 3.25rem;
  }
}
